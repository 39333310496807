<template>
  <div
    class="bg-color fixed-nav"
    :style="(fadeMenu) ? {zIndex: '1'} : {zIndex: '9'}"
  >
    <div style="position: relative; display: flex; justify-content: space-between; height: 100px; align-items: center; width: 90%; margin: auto;">
      <div>
        <v-img
          max-height="45"
          max-width="285"
          class="hidden-sm-and-down"
          style="cursor: pointer;"
          src="@/assets/logos/logo-bg-white.svg"
          alt="proplat-logo-white"
          @click="scrollToSection('Home')"
        ></v-img>
        <v-img
          max-height="45"
          class="d-md-none"
          max-width="45"
          style="cursor: pointer;"
          src="@/assets/logos/Grupo 380.png"
          alt="proplat-logo-white"
          @click="scrollToSection('Home')"
        ></v-img>
      </div>
      <div style="display: flex; align-items: center; justify-content: space-around;"> 
        <div class="hidden-md-and-down" style="display: flex; align-items: center;">
          <v-btn text @click="scrollToSection('Home')" class="txt-description mon-regular mb-0">{{ texts.general.home }}</v-btn>
          <v-btn text @click="scrollToSection('About')" class="txt-description mon-regular mb-0">{{ texts.general.aboutUs }}</v-btn>
          <v-btn text @click="scrollToSection('Buyers')" class="txt-description mon-regular mb-0">{{ texts.general.buyers }}</v-btn>
          <v-btn text @click="scrollToSection('Providers')" class="txt-description mon-regular mb-0">{{ texts.general.providers }}</v-btn>
          <p class="txt-description mon-regular mb-0">|</p>
          <v-menu offset-y >
          <template v-slot:activator="{ on, attrs }">
            <div class="ml-3 mt-2" style="display: flex; cursor: pointer;" @click="home" v-bind="attrs" v-on="on">
              <img
                v-if="selectLanguage == 'sp'"
                style="cursor: pointer;"
                src="@/assets/images/mexico.png"
                alt="proplat-logo-white"
              />
              <img
                v-else
                style="cursor: pointer;"
                src="@/assets/images/US.svg"
                alt="proplat-logo-white"
              />
              <v-icon>mdi-menu-down</v-icon>
            </div>
          </template>
          <language-component style="background-color: black; padding: 15px;"></language-component>
        </v-menu>
        </div>
        <div class="d-lg-none" style="display: flex; align-items: center;">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <div style="display: flex; cursor: pointer;" @click="home" v-bind="attrs" v-on="on">
                <img
                  v-if="selectLanguage == 'sp'"
                  style="cursor: pointer;"
                  src="@/assets/images/mexico.png"
                  alt="proplat-logo-white"
                />
                <img
                  v-else
                  style="cursor: pointer;"
                  src="@/assets/images/US.svg"
                  alt="proplat-logo-white"
                />
                <v-icon>mdi-menu-down</v-icon>
              </div>
            </template>
            <language-component style="background-color: black; padding: 15px;"></language-component>
          </v-menu>
          <p class="txt-description mon-regular mb-0" style="font-size: 40px; font-weight: 100;">|</p>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="showDialog = true" icon v-bind="attrs" v-on="on"><v-icon large>mdi-menu</v-icon></v-btn>
            </template>
            <div style="display: flex; flex-direction: column; background-color: white;" class="pa-6">
              <v-btn text @click="scrollToSection('Home')" class="txt-description mon-regular mb-0">{{ texts.general.home }}</v-btn>
              <v-btn text @click="scrollToSection('About')" class="txt-description mon-regular mb-0">{{ texts.general.aboutUs }}</v-btn>
              <v-btn text @click="scrollToSection('Buyers')" class="txt-description mon-regular mb-0">{{ texts.general.buyers }}</v-btn>
              <v-btn text @click="scrollToSection('Providers')" class="txt-description mon-regular mb-0">{{ texts.general.providers }}</v-btn>
            </div>
          </v-menu>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavbarComponent",
  data() {
    return {
      //VARIABLES
      showDialog: false,
      openMenu: false,
      texts: "",
      sSearch: "",
      arrSearch: [],
      notifications: [],
      countNotifications: 0,
      NumberProductsCart: 0,
    };
  },
  beforeMount() {
    this.texts = FILE.landing[this.selectLanguage];
    if (this.$store.state.sUserId) {
      this.getNotifications();
      this.getNotificationsCount();
      this.getShoppingCart();
    }
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    scrollToSection(sectionId) {
      const targetElement = document.getElementById(sectionId);
      if (targetElement) {
        const offset = 100; // Píxeles de desplazamiento deseado
        const targetPosition = targetElement.offsetTop - offset;
        window.scrollTo({
          top: targetPosition,
          behavior: 'smooth',
        });
      }
    },
    withoutNotifications: function () {
      if (this.selectLanguage == "sp") {
        this.mixInfo("Aún no tienes notificaciones");
      } else {
        this.mixInfo("You still have no notifications");
      }
    },
    redirectProfile: function () {
      this.$router
        .push({
          name: "Profile",
          params: {
            num: 1,
          },
        })
        .catch(() => {});
    },
    getShoppingCart: function () {
      const params = {
      }
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.put(
        `${URI}/api/v1/${this.selectLanguage}/customers/${this.$store.state.sUserId}/shopping-cart`, params, config 
      )
        .then((response) => {
          this.NumberProductsCart = response.data.iTotal;

          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          // this.mixError(error.response.data.message, error.response.status);
        });
    },
    formatDate: function (date) {
      var new_date = MOMENT(date, "YYYY/MM/DD");
      var format_date = MOMENT(new_date).format("MMM. DD, YYYY.");
      return format_date;
    },
    handleResize: function () {
      if (window.innerWidth > 960) {
        this.openMenu = false;
      }
    },
    focusSearch: function () {
      this.$refs.sSearch.focus();
    },
    openFadeMenu: function () {
      this.$store.commit("setFadeMenu", true);
    },
    home: function () {
      this.$router.push("/").catch(() => {});
    },
    redirectShoppingCart: function () {
      this.$router.push("/shopping-cart").catch(() => {});
    },
    clearSearch: function () {
      this.sSearch = "";
      this.arrSearch = [];
    },
    allSearching: function () {
      DB.get(`${URI}/api/v1/${this.selectLanguage}/products`, {
        headers: {},
        params: {
          sSearch: this.sSearch,
        },
      })
        .then((response) => {
          this.arrSearch = response.data.results;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    getNotifications: function () {
      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/users/${this.$store.state.sUserId}/notifications`,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.sToken}`,
          },
          params: {},
        }
      )
        .then((response) => {
          let arr = response.data.results.filter((e) => {
            return e.bRead == false;
          });
          this.notifications = arr;
        })
        .catch((error) => {
          // this.mixError(error.response.data.message, error.response.status);
        });
    },
    getNotificationsCount: function () {
      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/users/${this.$store.state.sUserId}/notifications/count`,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.sToken}`,
          },
          params: {},
        }
      )
        .then((response) => {
          this.countNotifications = response.data.results.iUnread;
        })
        .catch((error) => {
          // this.mixError(error.response.data.message, error.response.status);
        });
    },
    markAsReadNotification: function (id) {
      const config = {
          headers: {
            Authorization: `Bearer ${this.$store.state.sToken}`,
          },
        },
        payload = {
          bRead: true,
        };

      DB.patch(
        `${URI}/api/v1/${this.selectLanguage}/users/${this.$store.state.sUserId}/notifications/${id}`,
        payload,
        config
      )
        .then((response) => {
          this.getNotifications();
          this.getNotificationsCount();
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
    isLogged: function () {
      return this.$store.state.isLogged;
    },
    fadeMenu: function () {
      return this.$store.state.fadeMenu;
    },
    sFullName: function () {
      var text = "";
      if (this.$store.state.sFullName.split(" ").length == 4) {
        text =
          this.$store.state.sFullName.split(" ")[0].charAt(0) +
          this.$store.state.sFullName.split(" ")[2].charAt(0);
      } else if (this.$store.state.sFullName.split(" ").length == 3) {
        text =
          this.$store.state.sFullName.split(" ")[0].charAt(0) +
          this.$store.state.sFullName.split(" ")[1].charAt(0);
      } else if (this.$store.state.sFullName.split(" ").length == 2) {
        text =
          this.$store.state.sFullName.split(" ")[0].charAt(0) +
          this.$store.state.sFullName.split(" ")[1].charAt(0);
      }
      return text;
    },
    fadeNotificationCart: function () {
      return this.$store.state.fadeNotificationCart;
    },
    refresh: function () {
      return this.$store.state.refresh;
    },
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.texts = FILE.landing[this.selectLanguage];
        if (this.$store.state.sUserId) {
          this.getNotifications();
          this.getNotificationsCount();
          this.getShoppingCart();
        }
      }
    },
    sSearch: lodash.debounce(function (val) {
      if (this.sSearch !== "") {
        this.allSearching();
      }
    }, 200),
    refresh: function () {
      this.getShoppingCart();
    },
  },
};
</script>

<style scoped>
.cursor-pointer:hover {
  cursor: pointer;
}

.menu-arrow {
  margin-top: 55px;
  contain: initial;
  overflow: visible;
}

.menu-arrow::before {
  position: absolute;
  content: "";
  top: 0;
  right: 72px;
  transform: translateY(-170%);
  width: 10px;
  height: 13px;
  margin-right: 15px;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 13px solid #fff;
}

.menu-arrow2 {
  margin-top: 55px;
  contain: initial;
  overflow: visible;
}

.menu-arrow2::before {
  position: absolute;
  content: "";
  top: 0;
  right: 102px;
  transform: translateY(-170%);
  width: 10px;
  height: 13px;
  margin-right: 15px;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 13px solid #fff;
}

.view-not {
  background-color: #fff5f0;
  padding: 10px;
}

.no-view-not {
  background-color: #ffffff;
  padding: 10px;
}

.text-menu-title {
  text-align: left;
  font-size: 12px;
  letter-spacing: 0px;
  color: #000000;
  margin-bottom: 0px;
}

.text-menu-description {
  text-align: left;
  font-size: 12px;
  letter-spacing: 0px;
  color: #000000;
  margin-bottom: 0px;
  /* text-overflow: ellipsis; */
  /* overflow: hidden; */
  white-space: pre-wrap;
  width: 220px;
}

.text-menu-date {
  text-align: left;
  font-size: 10px;
  letter-spacing: 0px;
  color: #ff8181;
  opacity: 1;
  margin-bottom: 0px;
}

.rounded-icon {
  width: 45px;
  height: 45px;
  background: transparent linear-gradient(180deg, #ff8181 0%, #ffa77e 100%) 0%
    0% no-repeat padding-box;
  border-radius: 30px;
  opacity: 1;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.not-rounded-icon {
  width: 45px;
  height: 45px;
  background: transparent;
  border: 1px solid #ffa77e;
  border-radius: 30px;
  opacity: 1;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle-ext {
  background-color: #151d27;
  border: 1px solid #ff8181;
  width: 36px;
  height: 36px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle-int {
  background: transparent linear-gradient(225deg, #ffa77e 0%, #ff8181 100%) 0%
    0% no-repeat padding-box;
  border-radius: 0px 11px 11px 0px;
  opacity: 1;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle-text {
  text-align: center;
  font-size: 15px;
  letter-spacing: 0px;
  color: #fff;
  margin-bottom: 0px;
}

.user-info {
  background-color: #151d27;
  color: #fffc81;
  border-radius: 100%;
  padding: 5px;
  font-size: 14px;
  border: 1px solid #fffc81;
}

.height-100 {
  height: 100%;
}

.fixed-nav {
  position: fixed;
  width: 100%;
  z-index: 99999;
  margin-top: 0px;
}

.container-responsive-menu {
  position: absolute;
  width: 100%;
  z-index: 99999;
  padding: 25px 15px;
  height: 100px;
  /* UI Properties */
  background: #FFFFFF 0% 0% no-repeat padding-box;
  opacity: 1;
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px);
}

.input-search {
  width: 100%;
  height: 40px;
  background: #1f3042 0% 0% no-repeat padding-box;
  border: 1px solid #3b4c58;
  border-radius: 13px 0px 0px 13px;
  opacity: 1;
  text-align: left;
  letter-spacing: 0px;
  color: #ffffff;
  font-size: 14px;
  padding: 10px;
}

.input-search::placeholder {
  color: #ffffff;
  font-size: 14px;
}

.input-search:focus-visible {
  outline: none;
}

.button-search {
  min-width: 48px !important;
  height: 40px !important;
  background: transparent linear-gradient(228deg, #ffc431 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
  border-radius: 0px 13px 13px 0px;
  opacity: 1;
}

.img-logo {
  max-width: 286px;
  max-height: 43px;
}

.img-logo img {
  width: 100%;
}

@supports (object-fit: cover) {
  .img-logo img {
    object-fit: cover;
    object-position: center center;
  }
}

.bg-color {
  height: 100px;
  /* UI Properties */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombra suave */
  backdrop-filter: blur(10px); /* Aplicamos un filtro de desenfoque al contenedor */
  text-align: center;

}

.button-login {
  width: 120px;
  height: 40px !important;
  border: 1px solid #ffffff;
  border-radius: 10px;
  opacity: 1;
  text-align: center;
  letter-spacing: 0px;
  color: #ffffff !important;
  background-color: transparent !important;
  font-size: 14px !important;
  text-transform: initial;
  padding: 0px 15px;
}

.button-register {
  width: 120px;
  height: 40px !important;
  background: transparent linear-gradient(256deg, #ffc431 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  text-align: center;
  letter-spacing: 0px;
  color: #283c4d !important;
  font-size: 14px !important;
  text-transform: initial;
  padding: 0px 15px;
}

.txt-description {
  text-align: left;
  letter-spacing: 0px;
  font-weight: 800;
  color: black;
  opacity: 1;
  font-size: 20px;
}

@media (max-width: 960px) {
  .hidden-from-tablet {
    display: none;
  }
}

@media (min-width: 961px) {
  .hidden-from-desktop {
    display: none;
  }
}


</style>